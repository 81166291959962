.form > label:nth-of-type(2) {
  margin-top: 1rem;
}

.form label {
  color: var(--color-text-secondary);
  font-weight: 600;
}

.form input {
  width: 100%;
  height: 3.14em;
  box-sizing: border-box;
  padding: 11px 8px;
  margin-top: 8px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  color: var(--color-text-primary);
}
